import { z } from 'zod'

//TODO refacto products to not use this array but the one in dimensions package
export const allOrganizationProducts = ['mrph', 'mrpw', 'rcph', 'muta', 'rcda', 'es-rcph'] as const
export const organizationProducts = z.enum(allOrganizationProducts)

export type OrganizationType = (typeof allOrganizationType)[number]
export const allOrganizationType = ['orus', 'broker', 'partner'] as const
export const organizationTypeSchema = z.enum(allOrganizationType)

export const organizationDefinitionSchema = z.object({
  deleted: z.boolean().optional(),
  displayName: z.string(),
  address: z.string(),
  email: z.string(),
  orias: z.string(),
  legalEntity: z.string(),
  phone: z.string().optional(),
  selectableProducts: z.array(organizationProducts),
  hasEmbeddedFunnel: z.boolean().optional(),
  canTerminateContracts: z.boolean().optional(),
  emailDomain: z.string().optional(),
  type: organizationTypeSchema.optional(),
  commissionRate: z.record(organizationProducts, z.number().optional()).optional(),

  /** Hashes are computed with `createHash('sha256').update(token).digest('hex')` */
  apiTokenHashes: z.array(z.string()),
})

export const organizationsDefinitionSchema = z.record(z.string(), organizationDefinitionSchema)

export const orusOrganization = 'orus'

export type OrganizationDefinition = z.infer<typeof organizationDefinitionSchema>

export type Organization = OrganizationDefinition & { technicalName: string }

export type OrganizationsDefinition = z.infer<typeof organizationsDefinitionSchema>
