import { z } from 'zod'
import { roleSchema } from './role'

const membershipInOrganizationSchema = z.object({
  technicalName: z.string(),
  roles: z.array(roleSchema),
})

export type MembershipInOrganization = z.infer<typeof membershipInOrganizationSchema>

const organizationMembershipDefinitionSchema = z.object({
  deleted: z.boolean().optional(),
  organizations: z.array(membershipInOrganizationSchema),
})

export const organizationMembershipsDefinitionSchema = z.record(z.string(), organizationMembershipDefinitionSchema)

export type OrganizationMembershipDefinition = z.infer<typeof organizationMembershipDefinitionSchema>

export type OrganizationMembershipsDefinition = z.infer<typeof organizationMembershipsDefinitionSchema>

export type OrganizationMembership = OrganizationMembershipDefinition & { email: string }

export const e2eTestsAccounts: OrganizationMembershipsDefinition = {
  'techdata+sidecare@orus.eu': {
    organizations: [
      {
        technicalName: 'sidecare',
        roles: ['broker'],
      },
    ],
  },
  'techdata+orus-test@orus.eu': {
    organizations: [
      {
        technicalName: 'orus-test',
        roles: ['broker'],
      },
    ],
  },
}
